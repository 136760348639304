import React from 'react'
import { graphql } from 'gatsby'
import { ASSETS } from '@helpers'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { GatsbyImage } from 'gatsby-plugin-image'

import {
  Footer,
  Layout,
  MainTitle,
  MetaHeader,
  ShopeeIcon,
  Breadcrumb,
  OrderNowButton,
  ModalWithSlider,
} from '@components'

const title = 'Permainan'
const subtitle = `Pipe And Bullet Building Blocks`

const Boxes = ({ item }: any) => {
  const {
    name,
    childImageSharp: { gatsbyImageData },
  } = item

  const renameImg = name.replace(/(_|-)/g, ' ')
  const aspectRatio = gatsbyImageData.width / gatsbyImageData.height

  const autoSpanGridBasedOnAspectRatio =
    aspectRatio > 1
      ? 'col-span-1 sm:col-span-2'
      : aspectRatio < 1
      ? 'row-span-1 sm:row-span-2'
      : ''

  return (
    <div
      className={`h-full bg-white rounded shadow-md hover:shadow-lg border ${autoSpanGridBasedOnAspectRatio}`}>
      <GatsbyImage
        className='rounded cursor-pointer'
        image={gatsbyImageData}
        alt={renameImg}
        style={{
          height: '100%',
          width: '100%',
        }}
      />
    </div>
  )
}

export default function PipeAndBulletBuildingBlocks({ data }: any) {
  const {
    imgs: { nodes },
    mdx: {
      body: mainBody,
      frontmatter: {
        img: {
          childImageSharp: { gatsbyImageData },
        },
      },
    },
    site: {
      siteMetadata: {
        domain,
        social: {
          permainan: { shopee },
        },
      },
    },
  } = data

  return (
    <Layout>
      <MetaHeader
        title={title}
        description={subtitle}
        imgUrl={`${domain}${gatsbyImageData.images.fallback.src}`}
      />
      <div className='min-h-screen flex items-center justify-center'>
        <div className='flex-1 max-w-4xl mx-auto p-2 md:p-10'>
          <Breadcrumb />

          <MainTitle title={title} subtitle={subtitle} />

          <div className={'bg-white rounded shadow p-10'}>
            <div className='prose prose-xl'>
              <MDXProvider components={{}}>
                <MDXRenderer>{mainBody}</MDXRenderer>
              </MDXProvider>
            </div>

            <div className='mt-10 flex items-center justify-center'>
              <OrderNowButton shopeeUrl={shopee}>
                Tempah Sekarang, RM39 jee
              </OrderNowButton>
            </div>

            <div className='flex'>
              <video className='mt-10 max-w-full max-w-md' muted loop autoPlay>
                <source src={ASSETS.mp4.pipe_building_block} type='video/mp4' />
                <p>
                  Your browser doesn't support HTML5 video. Here is a
                  <a href={ASSETS.mp4.pipe_building_block}>link to the video</a>{' '}
                  instead.
                </p>
              </video>
            </div>
          </div>

          <div className='mt-10' />

          <ModalWithSlider
            nodes={nodes}
            RenderItem={({ item }: any) => <Boxes item={item} />}
          />
        </div>
      </div>

      <Footer>
        <li>
          <ShopeeIcon shopee={shopee} />
        </li>
      </Footer>
    </Layout>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        domain
        social {
          permainan {
            shopee
          }
        }
      }
    }
    mdx(frontmatter: { title: { eq: "Pipe And Bullet Building Blocks" } }) {
      body
      frontmatter {
        img {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    imgs: allFile(
      filter: {
        absolutePath: { regex: "/contents/permainan/" }
        extension: { ne: "mdx" }
      }
      sort: { fields: name }
    ) {
      nodes {
        id
        name
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`
